import { generateDepositHref } from '../../../utils/deposits';
import { IPropositions } from '../types/propositionBlock';

export enum PROPOSITION_TYPE {
  DEPOSITS = 'Вклады',
  MORTGAGE = 'Ипотека',
}

export const PROPOSITION_LINK = {
  DEPOSITS: {
    href: generateDepositHref(),
    title: 'Все вклады',
  },
  MORTGAGE: {
    href:
      'https://www.cian.ru/ipoteka-main/?utm_source=sravni&utm_medium=cpa&utm_campaign=sravni_zayavka_online&utm_content=cian_ipoteka_from_zayavka_online&utm_term=ipoteka_08&sravni_id=%7Btransaction_id%7D&noreferrer=1',
    title: 'Заявка на ипотеку в 7 банков',
    eventAction: 'Платный клик',
    eventLabel: 'Онлайн-заявка на ипотеку|Одна заявка в 7 банков|Переход из плиток с деталями',
    isExternalLink: true,
  },
};

export const MORTGAGE_DATA: IPropositions = [
  { title: 'Семейная', rateTitle: 'от 5,5%', href: '/ipoteka/lgotnaya-gospodderzhkoy/' },
  { title: 'Строительство дома', rateTitle: 'от 4,9%', href: '/ipoteka/na-stroitelstvo-doma/' },
  { title: 'IT-ипотека', rateTitle: 'от 4,7%', href: '/ipoteka/it-ipoteka/' },
  { title: 'Вторичка', rateTitle: 'от 16,7%', href: '/ipoteka/zhile-na-vtorichnom-rynke/' },
];
