// import { Currencies } from 'components/Currencies';
import JSCookie from 'js-cookie';
import { NextPage } from 'next';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ThemeName } from '@sravni/design-system-theme';
import { Grid } from '@sravni/react-design-system';
import { PreFooter } from '@sravni/react-pre-footer';
import { useStyles, useIsMobile } from '@sravni/react-utils';

// TODO: пока остается. Есть намерение вернуть кредитный рейтинг в том же виде, но в другое место. Обсуждено с Антоном Смирновым
// import { CreditScoring } from '../../components/CreditScoring';
// import { AddonsBlock } from '../../components/AddonsBlock';
// import { DownloadApp } from '../../components/DownloadApp';
import { Currencies } from '../../components/Currencies';
// import { DownloadApp } from '../../components/DownloadApp';
// import { LastDeal } from '../../components/LastDeal';
import Materials from '../../components/Materials';
// import { Monitoring } from '../../components/Monitoring';
// import { ProductBlock } from '../../components/ProductBlock';
import { PromoBlock } from '../../components/PromoBlock';
// import { Block } from '../../components/PropositionsBlock';
import { PropositionBlock } from '../../components/PropositionsBlock';
import RecentActions from '../../components/RecentActions';
import { ServiceMenu } from '../../components/ServiceMenu';
import { SpecialProjects } from '../../components/SpecialProjects';
import { Travel } from '../../components/Travel';
import { useMetadata } from '../../hooks/metadata';
import { useUser } from '../../hooks/users';
import { fetchBanners } from '../../store/reducers/advertising';
import styles from '../../styles/common.module.scss';

interface IIndexPageProps {
  isShowBVersion: boolean;
  theme: ThemeName;
}

const IndexPage: NextPage<IIndexPageProps> = ({ isShowBVersion, theme }) => {
  const { prefooter } = useMetadata();
  const user = useUser();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const cx = useStyles(styles);

  // TODO ab pe-2814
  //const abTestingSdk = useAbTestingSdk();
  //const isBtest = abTestingSdk.checkExperimentVariant('45b0dac3-ad00-4e0a', '1');
  const isBtest = false;

  const homeCookie = JSCookie.get('AB_HOME_DIRECT');
  // @ts-expect-error
  const isNeedBVersion = isShowBVersion || homeCookie === 'always';

  useEffect(() => {
    dispatch(
      fetchBanners({
        params: {
          BannerTypes: ['top', 'teaserInPropositions'],
          Page: 'home',
        },
      }),
    );
  }, [dispatch]);

  return (
    <>
      {/*TODO: пока остается. Есть намерение вернуть кредитный рейтинг в том же виде, но в другое место. Обсуждено с Антоном Смирновым*/}
      {/*{isBtest && isMobile && user && <CreditScoring />}*/}
      {/* {isBtest && <ProductBlock />} */}
      {/* TODO вероятно надо выпилить совсем (@anton smirnov) */}
      {/* <div className={cx('universal-wrapper', { 'universal-wrapper--last-deal': !!user })}>{user && <LastDeal />}</div> */}
      {!isBtest && (
        <>
          <PromoBlock darkTheme={theme === ThemeName.guinness} />
          <ServiceMenu />
        </>
      )}
      {
        <>
          <div className={cx('whiteBackground')}>
            <div
              className={cx(
                'universal-wrapper',
                'universal-wrapper--full-width-on-mobile',
                'whiteBackground',
                'inviteWrapper',
                {
                  'h-pt-32': !isMobile,
                  'h-pt-24': isMobile,
                },
              )}
            >
              <Grid.Row
                className={cx('')}
                justify={'space-between'}
                gutter={[32, isMobile ? 16 : 0]}
                wrap={isMobile}
                align={'middle'}
              >
                <Grid.Col span={isMobile ? 12 : 6}>
                  {/* <DownloadApp /> */}
                  <Travel />
                </Grid.Col>
                <Grid.Col span={isMobile ? 12 : 6}>
                  <Currencies />
                </Grid.Col>
              </Grid.Row>
            </div>
          </div>
          {!isBtest && user?.sub && <RecentActions />}
          <PropositionBlock />
        </>
      }
      {/* {isBtest && (
        <>
          <AddonsBlock />
          {user?.sub && <RecentActions />}
          <Monitoring />
        </>
      )} */}
      <Materials />
      <SpecialProjects />
      <PreFooter columns={prefooter} />
    </>
  );
};

IndexPage.getInitialProps = (ctx: App.ReduxNextPageContext) => {
  const {
    req: { cookies },
  } = ctx;

  const isShowBVersion = Boolean(cookies?.['AB_HOME_DIRECT'] === 'always');

  return {
    isShowBVersion,
    theme: ctx.req.__THEME__,
  };
};

export default IndexPage;
