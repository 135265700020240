import classNames from 'classnames';
import React, { useCallback } from 'react';

import { Icon } from '@sravni/react-design-system';
import {
  Analytics,
  Bank,
  BeautyAndHealth,
  BlankStamp,
  BookOpen,
  Briefcase,
  Bug,
  BusinessAccounting,
  Calculations,
  Car,
  CardPlus,
  CardTerminal,
  CardTimer,
  CardTransfer,
  ChartDown,
  Creation,
  CursorSelected,
  Deposit,
  Design,
  DollarExchange,
  Exchange,
  FileCrossOut,
  Globe,
  House,
  HouseSafety,
  IdentityCard,
  InsuranceHouse,
  Lungs,
  Management,
  Marketing,
  MyCredits,
  Needlework,
  Orders,
  Plane,
  PopularProfessions,
  PrizeCup,
  ProductsSearch,
  Programming,
  QnA,
  Scoring,
  Shield,
  ShieldLock,
  SoftSkills,
  Star,
  Stethoscope,
  Wallet,
  WalletBag,
  HouseSearch,
} from '@sravni/react-icons';

import { makeMainGEvent, pushEvent } from '../../utils/analytics';
import * as Icons from '../Icons/Products';

import styles from './service-menu.module.scss';

const itemsList: Record<string, { icon: any; category: string; isSpecialIcon?: boolean }> = {
  scoring: {
    icon: <Scoring />,
    category: 'Кредитный рейтинг',
  },
  mortgage: {
    icon: <House />,
    category: 'Ипотека',
  },
  mortgageInsurance: {
    icon: <HouseSafety />,
    category: 'Страхование ипотеки',
  },
  savings: {
    icon: <Deposit />,
    category: 'Вклады',
  },
  'credit-cards': {
    icon: <Icons.CreditCard />,
    category: 'Кредитные карты',
  },
  'debit-cards': {
    icon: <CardPlus />,
    category: 'Дебетовые карты',
  },
  microloan: {
    icon: <WalletBag />,
    category: 'Микрокредиты',
  },
  osago: {
    icon: <Car />,
    category: 'ОСАГО',
  },
  kasko: {
    icon: <Car />,
    category: 'КАСКО',
  },
  real: {
    icon: <ShieldLock />,
    category: 'Страхование недвижимости',
  },
  insurance: {
    icon: <Plane />,
    category: 'ВЗР',
  },
  sport: {
    icon: <PrizeCup />,
    category: 'НС',
  },
  refinansirovanie: {
    icon: <Exchange />,
    category: 'Рефинансирование кредитов',
  },
  potrebCredits: {
    icon: <Orders />,
    category: 'Потребительские кредиты',
  },
  creditCards: {
    icon: <CardTimer />,
    category: 'Кредитные карты',
  },
  brokerageService: {
    icon: <Briefcase />,
    category: 'Брокерское обслуживание',
  },
  autocred: {
    icon: <Car />,
    category: 'Автокредиты',
  },
  specialProjects: {
    icon: <Star />,
    category: 'Куда вложить деньги',
  },
  bankrotstvo: {
    icon: <ChartDown />,
    category: 'Банкротство',
  },

  credits: {
    icon: <Wallet />,
    category: 'Подбор кредита',
  },
  'selection-credit-cards': {
    icon: <Icons.Card />,
    category: 'Подбор кредитной карты',
  },
  currencies: {
    icon: <DollarExchange />,
    category: 'Валюты',
  },
  'transfer-to-card': {
    icon: <CardTransfer />,
    category: 'Переводы',
  },
  'banks-rating': {
    icon: <Bank />,
    category: 'Рейтинг банков',
  },
  'banks-reviews': {
    icon: <Icons.BanksReviews />,
    category: 'Отзывы о банках',
  },
  'insurance-rating': {
    icon: <Shield />,
    category: 'Рейтинг страховых',
  },
  'insurance-reviews': {
    icon: <Icons.InsuranceReviews />,
    category: 'Отзывы о страховых',
  },
  'strahovka-ot-klescha': {
    icon: <Bug />,
    category: 'Страхование от укуса клеща',
  },
  'strahovka-kriticheskie-bolezni': {
    icon: <Lungs />,
    category: 'Страхование от критических болезней',
  },
  dms: {
    icon: <Stethoscope />,
    category: 'Добровольное медицинское страхование',
  },
  'strahovanie-domov': {
    icon: <InsuranceHouse />,
    category: 'Страхование дома и дачи',
  },
  qna: {
    icon: <QnA />,
    category: 'Вопросы и ответы',
  },
  journal: {
    icon: <Calculations />,
    category: 'Контент',
  },
  alfacapital: {
    icon: <Icons.Alfacapital />,
    category: 'promo-alfa-capital',
  },
  pik: {
    icon: <Icons.Pik />,
    isSpecialIcon: true,
    category: 'pik',
  },

  // образование
  kursy: {
    icon: <PopularProfessions />,
    category: 'Образование',
  },
  programming: {
    icon: <Programming />,
    category: 'Разработка',
  },
  analytics: {
    icon: <Analytics />,
    category: 'Аналитика',
  },
  marketing: {
    icon: <Marketing />,
    category: 'Маркетинг',
  },
  management: {
    icon: <Briefcase />,
    category: 'Управление',
  },
  design: {
    icon: <Design />,
    category: 'Дизайн',
  },
  finansy: {
    icon: <Needlework />,
    category: 'Финансы',
  },
  samorazvitie: {
    icon: <SoftSkills />,
    category: 'Саморазвитие',
  },
  'marketing-kontent': {
    icon: <Creation />,
    category: 'Создание контента',
  },
  beauty: {
    icon: <BeautyAndHealth />,
    category: 'Красота и здоровье',
  },
  'inostrannye-yazyki': {
    icon: <Globe />,
    category: 'Иностранные языки',
  },
  'ege-oge': {
    icon: <BookOpen />,
    category: 'Подготовка к ЕГЭ и ОГЭ',
  },
  // бизнесс
  rko: {
    icon: <CardTerminal />,
    category: 'РКО',
  },
  businessCredits: {
    icon: <Briefcase />,
    category: 'Кредиты для бизнеса',
  },
  bankGuarantees: {
    icon: <MyCredits />,
    category: 'Банковские гарантии',
  },
  acquiring: {
    icon: <Icons.Acquiring />,
    category: 'Эквайринг',
  },
  VED: {
    icon: <Icons.Ved />,
    category: 'ВЭД',
  },
  depositsForBusiness: {
    icon: <Icons.CoinRub />,
    category: 'Вклады для бизнеса',
  },
  businessRegistration: {
    icon: <BlankStamp />,
    category: 'Регистрация бизнеса',
  },
  businessAccounting: {
    icon: <BusinessAccounting />,
    category: 'Бухгалтерия для бизнеса',
  },
  businessMarketplace: {
    icon: <Management />,
    category: 'Бизнес на маркетплейс',
  },
  leasing: {
    icon: <ProductsSearch />,
    category: 'Лизинг',
  },
  businessLiquidation: {
    icon: <FileCrossOut />,
    category: 'Ликвидация ИП',
  },
  biznesOformlenieSamozanyatogo: {
    icon: <IdentityCard />,
    category: 'Сервис регистрации самозанятых',
  },
  biznesVnesenieIzmeneniy: {
    icon: <CursorSelected />,
    category: 'Внесение изменений в ИП и ООО',
  },
  businessCounterpartyVerification: {
    icon: <CursorSelected />,
    category: 'Сервис проверки контрагентов',
  },
  realtyEstimate: {
    icon: <HouseSearch />,
    category: 'Оценка недвижимости',
  },
};

interface IMenuItemProps {
  item: {
    hide?: boolean;
    name: string;
    link: string;
    key: string;
  };
  className?: string;
}

const MenuItem: React.FC<IMenuItemProps> = React.memo(({ item, className }) => {
  const handleClickLink = useCallback(() => {
    pushEvent(
      makeMainGEvent({
        eventCategory: itemsList[item.key].category,
        eventAction: 'Переход из плиток|Все услуги',
      }),
    );
  }, [item.key]);

  if (item.hide) {
    return null;
  }

  return (
    <a className={classNames(styles['menu-item'], className)} href={item.link} onClick={handleClickLink}>
      <div className={classNames(styles.icon, { [styles['icon--specialIcon']]: itemsList[item.key]?.isSpecialIcon })}>
        <Icon icon={itemsList[item.key].icon} size={28} />
      </div>
      <div className={styles['menu-item__title']} dangerouslySetInnerHTML={{ __html: item.name }} />
    </a>
  );
});

export default MenuItem;
