import React from 'react';

import { Button, Dialog, Typography } from '@sravni/react-design-system';
import { useBoolean, useIsMobile } from '@sravni/react-utils';

import { makeMainGEvent, pushEvent } from '../../utils/analytics';

import MenuItem from './item';
import styles from './service-menu.module.scss';

const menuItems: Record<string, { key: string; name: any; link: string }> = {
  refinansirovanie: {
    key: 'refinansirovanie',
    name: 'Рефинансирование кредитов',
    link: '/kredity/promo/refinansirovanie/',
  },
  potrebCredits: {
    key: 'potrebCredits',
    name: 'Потребительские кредиты',
    link: '/kredity/',
  },
  creditCards: {
    key: 'creditCards',
    name: 'Кредитные карты',
    link: '/karty/',
  },
  brokerageService: {
    key: 'brokerageService',
    name: 'Брокерское обслуживание',
    link: '/invest/brokerskoe-obsluzhivanie/',
  },
  autocred: {
    key: 'autocred',
    name: 'Автокредиты',
    link: '/avtokredity/',
  },
  specialProjects: {
    key: 'specialProjects',
    name: 'Куда вложить деньги',
    link: '/promo/bonus/',
  },
  bankrotstvo: {
    key: 'bankrotstvo',
    name: 'Банкротство',
    link: '/bankrotstvo/',
  },
  VED: {
    key: 'VED',
    name: 'ВЭД',
    link: '/biznes-ved/',
  },

  credits: {
    key: 'credits',
    name: 'Подбор кредита',
    link: '/kredity/onlajn-zayavka-na-kredit/',
  },
  scoring: {
    key: 'scoring',
    name: 'Кредитный рейтинг',
    link: '/kredity/scoring/',
  },
  mortgage: {
    key: 'mortgage',
    name: 'Ипотека',
    link: '/ipoteka/',
  },
  savings: {
    key: 'savings',
    name: 'Вклады',
    link: '/vklady/',
  },
  'credit-cards': {
    key: 'credit-cards',
    name: 'Кредитные карты',
    link: '/karty/',
  },
  'debit-cards': {
    key: 'debit-cards',
    name: 'Дебетовые карты',
    link: '/debetovye-karty/',
  },
  microloan: {
    key: 'microloan',
    name: 'Займы',
    link: '/zaimy/',
  },
  'selection-credit-cards': {
    key: 'selection-credit-cards',
    name: 'Подбор кредитной карты',
    link: '/kredity/promo/karty/',
  },
  osago: {
    key: 'osago',
    name: 'ОСАГО',
    link: '/osago/',
  },
  kasko: {
    key: 'kasko',
    name: 'КАСКО',
    link: '/kasko/',
  },
  real: {
    key: 'real',
    name: 'Страхование квартиры',
    link: '/strahovanie-nedvizhimosti/',
  },
  covid: {
    key: 'covid',
    name: (
      <div>
        <div>Страхование</div>
        <div>от коронавируса</div>
      </div>
    ),
    link: '/promo/strahovanie-ot-koronavirusa/',
  },
  mortgageInsurance: {
    key: 'mortgageInsurance',
    name: 'Страхование ипотеки',
    link: '/strahovanie-ipoteki/',
  },
  insurance: {
    key: 'insurance',
    name: 'Туристическая страховка',
    link: '/vzr/',
  },
  sportInsurance: {
    key: 'sport',
    name: 'Страхование спортсменов',
    link: '/strahovanie-ns/',
  },
  'strahovka-ot-klescha': {
    key: 'strahovka-ot-klescha',
    name: 'Страхование от укуса клеща',
    link: 'strahovka-ot-klescha',
  },
  'strahovka-kriticheskie-bolezni': {
    key: 'strahovka-kriticheskie-bolezni',
    name: 'Страхование от критических болезней',
    link: '/strahovka-kriticheskie-bolezni/',
  },
  dms: {
    key: 'dms',
    name: 'Добровольное медицинское страхование',
    link: '/dms/',
  },
  'strahovanie-domov': {
    key: 'strahovanie-domov',
    name: 'Страхование дома и дачи',
    link: '/strahovanie-domov/',
  },
  currencies: {
    key: 'currencies',
    name: 'Курсы валют',
    link: '/valjuty/',
  },
  'transfer-to-card': {
    key: 'transfer-to-card',
    name: 'Перевод на карту',
    link: '/perevod-s-karty-na-kartu/',
  },
  'banks-rating': {
    key: 'banks-rating',
    name: 'Рейтинг банков',
    link: '/banki/rating/',
  },
  'banks-reviews': {
    key: 'banks-reviews',
    name: 'Отзывы о банках',
    link: '/banki/otzyvy/',
  },
  'insurance-rating': {
    key: 'insurance-rating',
    name: 'Рейтинг страховых',
    link: '/strakhovye-kompanii/rating/',
  },
  'insurance-reviews': {
    key: 'insurance-reviews',
    name: 'Отзывы о страховых',
    link: '/strakhovye-kompanii/otzyvy/',
  },
  qna: {
    key: 'qna',
    name: 'Вопросы и ответы',
    link: '/q/',
  },
  journal: {
    key: 'journal',
    name: 'Журнал',
    link: '/novosti/',
  },
  alfacapital: {
    key: 'alfacapital',
    name: 'Инвестиции с Альфа&#8209;Капитал',
    link: '/promo/alfacapital#invest-yourself',
  },
  alfacapitalDesktop: {
    key: 'alfacapital',
    name: 'Инвестиции с &laquo;Альфа&#8209;Капитал&raquo;',
    link: '/promo/alfacapital#invest-yourself',
  },
  pik: {
    key: 'pik',
    name: 'Ипотека в&nbsp;новостройках',
    link: '/special/pik/',
  },

  // образование
  kursy: {
    key: 'kursy',
    name: 'Образование',
    link: '/kursy/',
  },
  programming: {
    key: 'programming',
    name: 'Разработка',
    link: '/kursy/programmirovanie/',
  },
  analytics: {
    key: 'analytics',
    name: 'Аналитика',
    link: '/kursy/professii-analitik/',
  },
  marketing: {
    key: 'marketing',
    name: 'Маркетинг',
    link: '/kursy/professii-marketolog/',
  },
  management: {
    key: 'management',
    name: 'Управление',
    link: '/kursy/upravlencheskie/',
  },
  design: {
    key: 'design',
    name: 'Дизайн',
    link: '/kursy/dizajn/',
  },
  finansy: {
    key: 'finansy',
    name: 'Финансы',
    link: '/kursy/finansy/',
  },
  samorazvitie: {
    key: 'samorazvitie',
    name: 'Саморазвитие',
    link: '/kursy/samorazvitie/',
  },
  'marketing-kontent': {
    key: 'marketing-kontent',
    name: 'Создание контента',
    link: '/kursy/marketing-kontent/',
  },
  beauty: {
    key: 'beauty',
    name: 'Красота и здоровье',
    link: '/kursy/beauty/',
  },
  'inostrannye-yazyki': {
    key: 'inostrannye-yazyki',
    name: 'Иностранные языки',
    link: '/kursy/inostrannye-yazyki/',
  },
  'ege-oge': {
    key: 'ege-oge',
    name: 'Подготовка к ЕГЭ и ОГЭ',
    link: '/ege-oge/',
  },
  // бизнесс
  rko: {
    key: 'rko',
    name: 'РКО',
    link: '/rko/',
  },
  businessCredits: {
    key: 'businessCredits',
    name: 'Кредиты',
    link: '/biznes-kredity/',
  },
  bankGuarantees: {
    key: 'bankGuarantees',
    name: 'Банковские гарантии',
    link: '/biznes/bankovskie-garantii/',
  },
  acquiring: {
    key: 'acquiring',
    name: 'Эквайринг',
    link: '/biznes/ekvajring/',
  },
  businessRegistration: {
    key: 'businessRegistration',
    name: 'Регистрация бизнеса',
    link: '/biznes-registration/',
  },
  businessAccounting: {
    key: 'businessAccounting',
    name: 'Бухгалтерcкие услуги',
    link: '/biznes-accounting/',
  },
  businessMarketplace: {
    key: 'businessMarketplace',
    name: 'Бизнес на маркетплейс',
    link: '/biznes-marketplace/',
  },
  leasing: {
    key: 'leasing',
    name: 'Лизинг',
    link: '/biznes-leasing/',
  },
  businessLiquidation: {
    key: 'businessLiquidation',
    name: 'Ликвидация ИП',
    link: '/biznes-likvidaciya/',
  },
  biznesOformlenieSamozanyatogo: {
    key: 'biznesOformlenieSamozanyatogo',
    name: 'Сервис регистрации самозанятых',
    link: '/biznes-oformlenie-samozanyatogo/',
  },
  biznesVnesenieIzmeneniy: {
    key: 'biznesVnesenieIzmeneniy',
    name: 'Внесение изменений в ИП и ООО',
    link: '/biznes-vnesenie-izmeneniy/',
  },
  businessCounterpartyVerification: {
    key: 'businessCounterpartyVerification',
    name: 'Сервис проверки контрагентов',
    link: '/kontragent/',
  },
  depositsForBusiness: {
    key: 'depositsForBusiness',
    name: 'Вклады для юрлиц',
    link: '/biznes-vklady/',
  },
  realtyEstimate: {
    key: 'realtyEstimate',
    name: 'Оценка недвижимости',
    link: '/ocenka-nedvizhimosti/',
  },
};

const allPopupItems = [
  {
    label: 'Деньги',
    items: [
      menuItems.credits,
      menuItems['selection-credit-cards'],
      menuItems.scoring,
      menuItems.savings,
      menuItems.mortgage,
      menuItems.potrebCredits,
      menuItems.pik,
      menuItems.creditCards,
      menuItems['debit-cards'],
      menuItems.microloan,
      menuItems.refinansirovanie,
      menuItems.currencies,
      menuItems['transfer-to-card'],
      menuItems.brokerageService,
      menuItems.autocred,
      menuItems.specialProjects,
      menuItems.bankrotstvo,
      menuItems.depositsForBusiness,
      menuItems.realtyEstimate,
    ],
  },
  {
    label: 'Страхование',
    items: [
      menuItems.osago,
      menuItems.kasko,
      menuItems.insurance,
      menuItems.mortgageInsurance,
      menuItems.sportInsurance,
      menuItems.real,
      menuItems['strahovka-ot-klescha'],
      menuItems['strahovka-kriticheskie-bolezni'],
      menuItems['dms'],
      menuItems['strahovanie-domov'],
    ],
  },
  {
    label: 'Онлайн-курсы',
    items: [
      menuItems.programming,
      menuItems.analytics,
      menuItems.marketing,
      menuItems.management,
      menuItems.design,
      menuItems.finansy,
      menuItems.samorazvitie,
      menuItems['marketing-kontent'],
      menuItems.beauty,
      menuItems['inostrannye-yazyki'],
      menuItems['ege-oge'],
    ],
  },
  {
    label: 'Для бизнеса',
    items: [
      menuItems.rko,
      menuItems.businessCredits,
      menuItems.bankGuarantees,
      menuItems.acquiring,
      menuItems.VED,
      menuItems.businessRegistration,
      menuItems.businessAccounting,
      menuItems.businessMarketplace,
      menuItems.leasing,
      menuItems.businessLiquidation,
      menuItems.biznesOformlenieSamozanyatogo,
      //menuItems.biznesVnesenieIzmeneniy,
      menuItems.businessCounterpartyVerification,
    ],
  },
  {
    label: 'Полезное',
    items: [
      menuItems['banks-rating'],
      menuItems['banks-reviews'],
      menuItems['insurance-rating'],
      menuItems['insurance-reviews'],
      menuItems['qna'],
      menuItems['journal'],
    ],
  },
];

export const ServiceMenu = React.memo(() => {
  const [isShown, setModalShown] = useBoolean(false);
  const isMobile = useIsMobile();

  const handleAllServicesClick = React.useCallback(() => {
    setModalShown.on();
    pushEvent(
      makeMainGEvent({
        eventAction: 'Открытие попапа',
        eventCategory: 'Все услуги',
        eventLabel: undefined,
        eventValue: undefined,
      }),
    );
  }, [setModalShown]);

  const handleModalClose = React.useCallback(() => {
    setModalShown.off();
  }, [setModalShown]);

  return (
    <>
      <div className={styles.wrapper} suppressHydrationWarning>
        <div className={styles.inner}>
          {isMobile ? (
            <Typography.Link className={styles['header-link']}>
              <Button
                className={styles['header-link']}
                color="dark"
                variant="outlined"
                onClick={handleAllServicesClick}
              >
                Все услуги
              </Button>
            </Typography.Link>
          ) : (
            <Typography.Text size={isMobile ? 10 : 14} uppercase={isMobile}>
              <Typography.Link className={styles['header-link']} strong onClick={handleAllServicesClick}>
                Все услуги
              </Typography.Link>
            </Typography.Text>
          )}
        </div>
      </div>
      <Dialog
        closable={true}
        onClose={handleModalClose}
        visible={isShown}
        fullscreen={isMobile}
        className={styles.dialog}
      >
        <Dialog.Header title={'Все услуги'} />
        <Dialog.Content>
          {allPopupItems.map((section) => (
            <div key={section.label} className={styles.modal__section}>
              <Typography.Heading level={3} as={'div'} className={styles.modal__header}>
                {section.label}
              </Typography.Heading>
              <div className={styles['modal__row']}>
                {section.items.map((item) => (
                  <MenuItem item={item} key={`menu-item-dialog-${item.link}`} className={styles['menu-item--modal']} />
                ))}
              </div>
            </div>
          ))}
        </Dialog.Content>
      </Dialog>
    </>
  );
});
